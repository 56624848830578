import { useEffect } from "react";

import { apiBase } from "../../../config";

const NachhaltigerGeschmackPage = ({ params: { path } }) => {
    useEffect(
        () =>
            (window.location.href = new URL(
                `aktionen/nachhaltiger-geschmack/${path}`,
                apiBase
            ).toString()),
        [path]
    );

    return null;
};
export default NachhaltigerGeschmackPage;
